import { useEffect, useState } from 'react'

import { NetworkStatus } from '@apollo/client'
import {
  ActionIcon,
  Badge,
  Box,
  Indicator,
  Loader,
  Menu,
  MenuDropdown,
  MenuTarget,
} from '@mantine/core'
import { Calendar } from '@mantine/dates'
import { useInView } from 'react-intersection-observer'

import { navigate, routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { useCurrentCompanyContext } from 'src/context/CurrentCompanyContext'
import { GET_BOOKINGS } from 'src/graphql/bookings.graphql'
import { BookingTimeframe } from 'src/graphql/types/bookings'
import IconCalendar from 'src/icons/IconCalendar'
import IconExpand from 'src/icons/IconExpand'

import BookingCard from './BookingCard'

const AppointmentsList = ({ recruiters = [] }) => {
  const [upcoming, setUpcoming] = useState(true)
  const currentCompany = useCurrentCompanyContext()
  const isVoiceAgentEnabled = currentCompany?.voiceAgentEnabled

  const { ref, inView } = useInView({
    threshold: 0,
  })

  const LIMIT = 10

  const { data, loading, fetchMore, networkStatus } = useQuery(GET_BOOKINGS, {
    variables: {
      isInfiniteScroll: true,
      filters: {
        applicantId: null,
        recruiters: recruiters,
        startDate: null,
        endDate: null,
        timeframe: upcoming
          ? BookingTimeframe.UPCOMING
          : BookingTimeframe.PREVIOUS,
      },
      pageInfo: { offset: 0, limit: LIMIT },
    },
  })

  useEffect(() => {
    if (inView) {
      fetchMore({
        variables: {
          pageInfo: {
            offset: data?.getBookings?.items?.length ?? 0,
            limit: LIMIT,
          },
        },
      })
    }
  }, [inView])

  return (
    <div className="flex-2 flex h-full flex-col gap-3 overflow-auto">
      <div className="flex flex-row items-center gap-2 text-base font-semibold text-doubleNickel-gray-900">
        Booked Appointments{' '}
        <ActionIcon
          variant="subtle"
          color="gray"
          onClick={() => {
            navigate(routes.calendar())
          }}
        >
          <IconExpand className="h-3 fill-none stroke-doubleNickel-gray-700" />
        </ActionIcon>
      </div>
      <div className="flex flex-row gap-2">
        <Box
          className={`flex flex-row items-center justify-center gap-1 rounded p-2 text-xs hover:cursor-pointer hover:bg-doubleNickel-gray-50 ${
            upcoming && 'bg-doubleNickel-gray-100 font-medium '
          }`}
          onClick={() => setUpcoming(true)}
        >
          Upcoming
          {upcoming && (
            <Badge size="xs" color="gray" variant="light">
              {data?.getBookings?.totalCount}
            </Badge>
          )}
        </Box>
        <Box
          className={`flex flex-row items-center justify-center gap-1 rounded p-2 text-xs hover:cursor-pointer hover:bg-doubleNickel-gray-50 ${
            !upcoming && 'bg-doubleNickel-gray-100 font-medium '
          }`}
          onClick={() => setUpcoming(false)}
        >
          Previous
          {!upcoming && (
            <Badge size="xs" color="gray" variant="light">
              {data?.getBookings?.totalCount}
            </Badge>
          )}
        </Box>
        <Menu position="bottom-end">
          <MenuTarget>
            <ActionIcon
              variant="subtle"
              color="gray"
              className="ml-auto hidden"
              disabled
            >
              <IconCalendar className="h-4 fill-none stroke-doubleNickel-gray-700" />
            </ActionIcon>
          </MenuTarget>
          <MenuDropdown className="p-3">
            <Calendar
              renderDay={(date) => {
                const day = date.getDate()
                return (
                  <Indicator
                    size={6}
                    color="blue"
                    offset={-4}
                    disabled={day !== 12 && day !== 14 && day !== 18}
                  >
                    <div>{day}</div>
                  </Indicator>
                )
              }}
            />
          </MenuDropdown>
        </Menu>
      </div>
      {isVoiceAgentEnabled ? (
        <>
          {loading && networkStatus !== NetworkStatus.fetchMore ? (
            <Loader
              size={'sm'}
              className="flex w-full flex-1 items-center justify-center"
              color="gray"
            />
          ) : data?.getBookings?.items.length > 0 ? (
            <div className="flex h-full flex-col gap-3 overflow-auto">
              {data?.getBookings?.items.map((booking) => (
                <BookingCard key={booking.bookingId} booking={booking} />
              ))}
              {networkStatus === NetworkStatus.fetchMore && (
                <Loader
                  size="sm"
                  className="flex w-full flex-row justify-center"
                  color="gray"
                />
              )}
              {data?.getBookings?.items.length <
                data?.getBookings?.totalCount && (
                <div ref={ref} className="opacity-0">
                  Inview placeholder
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-1 items-center justify-center text-center text-sm font-medium text-doubleNickel-gray-600">
              No {upcoming ? 'upcoming' : 'previous'} appointments
            </div>
          )}
        </>
      ) : isVoiceAgentEnabled === undefined ? (
        <Loader
          size={'sm'}
          className="flex w-full flex-1 items-center justify-center"
          color="gray"
        />
      ) : (
        <div className="flex flex-1 items-center justify-center text-center text-sm font-medium text-doubleNickel-gray-600">
          Enable your AI assistant to automatically book appointments with
          candidates!
        </div>
      )}
    </div>
  )
}

export default AppointmentsList
