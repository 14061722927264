import { ActionIcon, Box, Loader } from '@mantine/core'

import { useQuery } from '@redwoodjs/web'

import { toast } from 'src/components/Overlays/Toast/Toast'
import { GET_STEPS_METRICS } from 'src/graphql/steps.graphql'
import { ApplicantStage } from 'src/graphql/types/applicants'
import { StepStatus } from 'src/graphql/types/steps'
import IconRightArrow from 'src/icons/IconRightArrow'
import { formatStepType } from 'src/lib/formatters'
import { cn } from 'src/utils'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}
const ProspectChecklist = ({ selectedRecruiters, onMetricClick }) => {
  const { data: stepsData, loading: loadingStepsMetrics } = useQuery(
    GET_STEPS_METRICS,
    {
      variables: {
        filters: {
          recruiters: selectedRecruiters.map((recruiter) => recruiter.value),
          OR: [
            {
              status: StepStatus.PENDING_REVIEW,
            },
          ],
          applicantStages: [ApplicantStage.PROSPECT],
        },
      },
      onError,
    }
  )

  const pendingActions = stepsData?.stepMetrics?.items

  return (
    <div className="flex h-full flex-1 flex-col gap-2 overflow-hidden">
      <div className="flex flex-col">
        <div className="flex flex-row justify-between text-sm text-doubleNickel-gray-800">
          Pending actions for prospective candidates
        </div>
      </div>
      <div
        className={cn(
          'grid grid-cols-4 bg-doubleNickel-gray-50 p-2 text-xs font-semibold text-doubleNickel-gray-700'
        )}
      >
        <div className="col-span-2">Category</div>
        <div>Amount</div>
        <div className="invisible">placeholder</div>
      </div>
      <div className="mb-6 flex h-full flex-col overflow-auto">
        {loadingStepsMetrics ? (
          <div className="flex flex-1 flex-col items-center justify-center">
            <Loader size="sm" color="gray" />
          </div>
        ) : (
          <>
            {pendingActions?.map((metric) => (
              <Box
                key={metric.name}
                onClick={() =>
                  onMetricClick('steps', `${metric.name}-PENDING_REVIEW`)
                }
              >
                <div
                  className={cn(
                    'border-bg-doubleNickel-gray-600 grid min-h-10 cursor-pointer grid-cols-4 items-center px-2 py-1 text-xs text-doubleNickel-gray-700 hover:bg-doubleNickel-gray-100'
                  )}
                >
                  <div className="col-span-2">
                    {formatStepType(metric.name)}
                  </div>
                  <div>{metric.value}</div>
                  <ActionIcon variant="subtle" color="gray" className="ml-auto">
                    <IconRightArrow className="h-4 w-4 fill-none stroke-doubleNickel-gray-700" />
                  </ActionIcon>
                </div>
              </Box>
            ))}
          </>
        )}
        {pendingActions && pendingActions.length === 0 && (
          <div className="flex flex-1 items-center justify-center text-sm font-medium text-doubleNickel-gray-500">
            You have no pending actions
          </div>
        )}
      </div>
    </div>
  )
}

export default ProspectChecklist
