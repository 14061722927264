import { Box, Indicator } from '@mantine/core'
import { Calendar } from '@mantine/dates'

import { navigate, routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { GET_BOOKINGS_COUNT } from 'src/graphql/bookings.graphql'

const CalendarCard = ({ selectedRecruiters }) => {
  const startDate = new Date()
  startDate.setDate(1)
  startDate.setHours(0, 0, 0, 0)
  const endDate = new Date()
  endDate.setMonth(endDate.getMonth() + 1)
  endDate.setDate(0)
  endDate.setHours(23, 59, 59, 999)

  const { data, loading } = useQuery(GET_BOOKINGS_COUNT, {
    variables: {
      filters: {
        startDate: startDate,
        endDate: endDate,
        recruiters: selectedRecruiters
          ? selectedRecruiters.map((recruiter) => recruiter.value)
          : [],
      },
    },
  })

  const bookings = data?.getBookingsCount ?? []

  return (
    <Calendar
      size="xs"
      firstDayOfWeek={0}
      className="border border-doubleNickel-gray-200 p-1 shadow-sm"
      classNames={{
        calendarHeader: 'invisible h-0',
      }}
      renderDay={(date) => {
        const day = date.getDate()
        return (
          <Indicator
            size={6}
            color="blue"
            offset={-4}
            disabled={
              loading ||
              date < startDate ||
              date > endDate ||
              bookings.find(
                (booking) => booking.date === date.toISOString().split('T')[0]
              )?.count === 0
            }
          >
            <Box
              onClick={() => {
                const startDate = new Date(date)
                startDate.setDate(date.getDate() - date.getDay())
                const endDate = new Date(startDate)
                endDate.setDate(startDate.getDate() + 6)
                navigate(
                  routes.calendar({
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                  })
                )
              }}
            >
              {day}
            </Box>
          </Indicator>
        )
      }}
    />
  )
}

export default CalendarCard
