import { Box, Divider } from '@mantine/core'
import dayjs from 'dayjs'

import { navigate, routes } from '@redwoodjs/router'

import { useApplicantDrawerContext } from 'src/context/ApplicantDrawerContext'
import { buildFullName } from 'src/lib/formatters'
import { cn } from 'src/utils'

const BookingCard = ({ booking }) => {
  const { handleOpen } = useApplicantDrawerContext()

  return (
    <Box
      className="flex flex-row items-center gap-3 rounded border-[1px] border-doubleNickel-gray-200 p-3 text-xs font-medium text-doubleNickel-gray-800 hover:cursor-pointer hover:bg-doubleNickel-gray-50"
      onClick={() => {
        const startDate = new Date(booking.bookingStartTime)
        startDate.setDate(startDate.getDate() - startDate.getDay())
        const endDate = new Date(startDate)
        endDate.setDate(startDate.getDate() + 6)
        navigate(
          routes.calendar({
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          })
        )
      }}
    >
      <div
        className={cn(
          'flex w-[10%] flex-col items-center justify-center text-sm ',
          dayjs(booking.bookingStartTime).isSame(dayjs(), 'day')
            ? 'text-doubleNickel-success-600'
            : booking.status === 'CANCELLED'
            ? 'text-doubleNickel-gray-700 line-through'
            : 'text-doubleNickel-gray-700'
        )}
      >
        {dayjs(booking.bookingStartTime).format('M') !==
        dayjs(new Date()).format('M')
          ? dayjs(booking.bookingStartTime).format('MMM')
          : dayjs(booking.bookingStartTime).format('ddd')}
        <div className="text-lg">
          {dayjs(booking.bookingStartTime).format('DD')}
        </div>
      </div>
      <Divider orientation="vertical" />
      <div>
        <div className="flex flex-row items-baseline gap-1">
          <Box
            className="hover:cursor-pointer hover:text-doubleNickel-brand-500 hover:underline"
            onClick={(e) => {
              if (booking.applicant?.applicantId) {
                //If the user holds down the meta key (command on mac, control on windows) and clicks on the link, it will open in a new tab
                if (e.metaKey) {
                  window.open(
                    routes.applicantDetails({
                      id: booking.applicant.applicantId,
                      tab: 'application',
                    }),
                    '_blank' // Opens in a new tab
                  )
                } else {
                  handleOpen(booking.applicant.applicantId)
                }
              }
            }}
          >
            {buildFullName(booking.applicant)}
          </Box>
          <div>{` <> `}</div>
          <div>{buildFullName(booking.employee)}</div>{' '}
        </div>
        <div className="text-doubleNickel-gray-600">
          {dayjs(booking.bookingStartTime).format('hh:mm A')} -{' '}
          {dayjs(booking.bookingEndTime).format('hh:mm A')}
        </div>
      </div>
    </Box>
  )
}

export default BookingCard
