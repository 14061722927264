import { useState } from 'react'

import { ActionIcon, Indicator, Box, Loader } from '@mantine/core'
import { StepType } from 'types/graphql'

import { Link, routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { toast } from 'src/components/Overlays/Toast/Toast'
import { GET_EXPIRING_DOCUMENT_BY_CATEGORY } from 'src/graphql/documents.graphql'
import { ApplicantStage } from 'src/graphql/types/applicants'
import IconRightArrow from 'src/icons/IconRightArrow'
import { formatDate, formatSnakeValue } from 'src/lib/formatters'
import { formatStepType } from 'src/lib/formatters'
import { cn } from 'src/utils'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const DocumentChecklist = ({ recruiters, onMetricClick }) => {
  const LIMIT = 50
  const [page, setPage] = useState(1)

  const { data: expiringDocuments, loading: loadingExpiringDocuments } =
    useQuery(GET_EXPIRING_DOCUMENT_BY_CATEGORY, {
      variables: {
        filters: {
          recruiters: recruiters,
        },
        pageInfo: { offset: (page - 1) * LIMIT, limit: LIMIT },
      },
      onError,
    })

  return (
    <div className="flex h-full flex-1 flex-col gap-2 overflow-hidden ">
      <div className="flex flex-col">
        <div className="flex flex-row justify-between text-sm text-doubleNickel-gray-800">
          Upcoming expirations for hired drivers
          <Box
            className="flex flex-row items-center gap-1 text-doubleNickel-gray-600 hover:underline"
            onClick={() =>
              onMetricClick('applicantStages', [ApplicantStage.HIRED])
            }
          >
            See all hired drivers
            <IconRightArrow className="h-3 fill-none stroke-doubleNickel-gray-700" />
          </Box>
        </div>
      </div>
      <div
        className={cn(
          'grid grid-cols-4 bg-doubleNickel-gray-50 p-2 text-xs font-semibold text-doubleNickel-gray-700'
        )}
      >
        <div>Category</div>
        <div>Name</div>
        <div>Expiration </div>
        <div className="invisible">placeholder</div>
      </div>
      <div className="mb-6 flex h-full flex-col overflow-auto">
        {loadingExpiringDocuments ? (
          <div className="flex flex-1 flex-col items-center justify-center">
            <Loader size="sm" color="gray" />
          </div>
        ) : (
          <>
            {expiringDocuments?.documentsExpirationByCategory &&
              expiringDocuments.documentsExpirationByCategory.items.map(
                (document) => (
                  <Link
                    key={document.documentId}
                    to={routes.applicantDetails({
                      id: document.applicant.applicantId,
                      tab: 'documents',
                      dqf: document.category,
                    })}
                  >
                    <div
                      className={cn(
                        'border-bg-doubleNickel-gray-600 grid min-h-10 cursor-pointer grid-cols-4 items-center px-2 py-1 text-xs text-doubleNickel-gray-700 hover:bg-doubleNickel-gray-100'
                      )}
                    >
                      <div>{formatStepType(document.category as StepType)}</div>
                      <div>{formatSnakeValue(document.applicant.fullName)}</div>
                      <Indicator
                        color={
                          new Date(document.expirationDate) < new Date()
                            ? 'red'
                            : 'yellow'
                        }
                        position="middle-end"
                        offset={-14}
                        size={6}
                      >
                        <div>{formatDate(document.expirationDate)}</div>
                      </Indicator>
                      <ActionIcon
                        variant="subtle"
                        color="gray"
                        className="ml-auto"
                      >
                        <IconRightArrow className="h-4 w-4 fill-none stroke-doubleNickel-gray-700" />
                      </ActionIcon>
                    </div>
                  </Link>
                )
              )}
          </>
        )}
        {expiringDocuments?.documentsExpirationByCategory?.items?.length ===
          0 && (
          <div className="flex h-full flex-col items-center justify-center text-sm font-medium text-doubleNickel-gray-500">
            No expiring documents
          </div>
        )}
      </div>
    </div>
  )
}

export default DocumentChecklist
