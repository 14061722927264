import { useState } from 'react'

import { Divider } from '@mantine/core'

import { navigate } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import Tag from 'src/components/DataDisplay/Tag/Tag'
import IconCheckmark from 'src/icons/IconCheckmark'

import AppointmentsList from './components/AppointmentsList'
import CalendarCard from './components/CalendarCard'
import DashboardHeader from './components/DashboardHeader'
import DocumentChecklist from './components/DocumentChecklist'
import ProspectChecklist from './components/ProspectChecklist'
import ProspectStatistics from './components/ProspectStatistics'

const DashboardPage = () => {
  const { currentUser } = useAuth()
  const fullName = `${currentUser?.firstName} ${currentUser?.lastName}`
  const [selectedRecruiters, setSelectedRecruiters] = useState([])

  const onMetricClick = (key, value) => {
    const queryParams = new URLSearchParams(window.location.search)
    queryParams.set(key, value)

    const recruiterIds = selectedRecruiters
      .map((recruiter) => recruiter.value)
      .join(',')
    if (recruiterIds.length > 0) {
      queryParams.set('recruiters', recruiterIds)
    }
    navigate(`/applicants?${queryParams.toString()}`)
  }

  // Create a new date object for today, but reset the time to midnight.
  const todayAtMidnight = new Date()
  todayAtMidnight.setHours(0, 0, 0, 0)

  // Calculate 30 days ago from the above date, also at midnight.
  const timeAgo = new Date(todayAtMidnight)
  timeAgo.setDate(todayAtMidnight.getDate() - 30)

  return (
    <div className="flex h-full flex-row gap-4 px-5 pb-5 ">
      <div className="flex h-full w-full flex-1 flex-col gap-5">
        {fullName && (
          <DashboardHeader
            name={fullName}
            selectedRecruiters={selectedRecruiters}
            setSelectedRecruiters={setSelectedRecruiters}
          />
        )}
        <div className="flex w-full flex-row items-center gap-4 overflow-hidden">
          <div className="flex flex-row items-center gap-2 overflow-auto">
            {selectedRecruiters.map((recruiter) => (
              <Tag
                key={recruiter.value}
                onClose={() => {
                  setSelectedRecruiters(
                    selectedRecruiters.filter(
                      (selectedRecruiter) =>
                        selectedRecruiter.value !== recruiter.value
                    )
                  )
                }}
              >
                {' '}
                {recruiter.label}
              </Tag>
            ))}
          </div>

          <Divider orientation="vertical" className="ml-auto" />
          <div className="text-left text-xs font-medium text-doubleNickel-gray-700">
            Suggested:
          </div>

          <Tag
            clickable={true}
            onClick={() => {
              if (
                selectedRecruiters.find(
                  (recruiter) => recruiter.value === currentUser.employeeId
                )
              ) {
                // Remove the recruiter
                setSelectedRecruiters(
                  selectedRecruiters.filter(
                    (recruiter) => recruiter.value !== currentUser.employeeId
                  )
                )
              } else {
                // Add the recruiter
                setSelectedRecruiters([
                  ...selectedRecruiters,
                  {
                    label: fullName,
                    value: currentUser.employeeId,
                  },
                ])
              }
            }}
            showCloseButton={false}
          >
            {selectedRecruiters.find(
              (recruiter) => recruiter.value === currentUser.employeeId
            ) && (
              <IconCheckmark className="h-3 w-3 fill-none stroke-doubleNickel-brand-400" />
            )}
            {fullName}
          </Tag>
        </div>
        <div className="flex h-full flex-row gap-4 overflow-hidden">
          <div className="flex flex-1 flex-col gap-3">
            <div className="flex flex-row items-center gap-3">
              <ProspectStatistics
                selectedRecruiters={selectedRecruiters}
                onMetricClick={onMetricClick}
                timeAgo={timeAgo}
              />
              <CalendarCard selectedRecruiters={selectedRecruiters} />
            </div>
            <div className="mb-1 flex flex-1 flex-col overflow-hidden rounded border border-doubleNickel-gray-200 p-3 shadow-sm">
              <div className="text-base font-medium text-doubleNickel-gray-900">
                Your to-do list
              </div>
              <div className="flex h-full flex-row items-start gap-4">
                <ProspectChecklist
                  selectedRecruiters={selectedRecruiters}
                  onMetricClick={onMetricClick}
                />
                <DocumentChecklist
                  recruiters={selectedRecruiters.map(
                    (recruiter) => recruiter.value
                  )}
                  onMetricClick={onMetricClick}
                />
              </div>
            </div>
          </div>
          <Divider orientation="vertical" />
          <div className="w-[25%]">
            <AppointmentsList
              recruiters={selectedRecruiters.map(
                (recruiter) => recruiter.value
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardPage
