import { useState } from 'react'

import { Box } from '@mantine/core'

import { useQuery } from '@redwoodjs/web'

import { toast } from 'src/components/Overlays/Toast/Toast'
import { GET_APPLICANT_METRICS } from 'src/graphql/applicants.graphql'
import { ApplicantStage, HiringStage } from 'src/graphql/types/applicants'
import { formatSnakeValue } from 'src/lib/formatters'
import { cn } from 'src/utils'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const keyMetricsOrder = [
  ApplicantStage.HIRED,
  ApplicantStage.DISQUALIFIED,
  ApplicantStage.NOT_INTERESTED,
  ApplicantStage.TERMINATED,
]

const prospectMetricsOrder = [
  HiringStage.LEAD,
  HiringStage.APPLICATION,
  HiringStage.QUALIFICATION,
  HiringStage.ONBOARDING,
]

const ProspectStatistics = ({
  selectedRecruiters,
  onMetricClick,
  timeAgo = undefined,
}) => {
  const [selectedType, setSelectedType] = useState('prospects')

  /*** QUERIES  ****/
  const { data: hiringStageData, loading: loadingHiringStageMetrics } =
    useQuery(GET_APPLICANT_METRICS, {
      variables: {
        groupBy: 'hiringStage',
        filters: {
          applicantStages: {
            OR: [
              {
                applicantStage: ApplicantStage.PROSPECT,
              },
            ],
          },
          recruiters: selectedRecruiters.map((recruiter) => recruiter.value),
        },
      },
      onError,
    })

  const { data: applicantStageData, loading: loadingApplicantStageMetrics } =
    useQuery(GET_APPLICANT_METRICS, {
      variables: {
        groupBy: 'applicantStage',
        filters: {
          recruiters: selectedRecruiters.map((recruiter) => recruiter.value),
          applicationDate: {
            gte: timeAgo?.toISOString(),
          },
        },
      },
      onError,
    })

  /*** RESULTS ***/
  const applicantStageMetrics = keyMetricsOrder.map((stage) => {
    const metric = applicantStageData?.applicantMetrics?.items.find(
      (item) => item.name === stage
    )
    return metric || { name: stage, value: 0 }
  })
  const hiringStageMetrics = prospectMetricsOrder.map((stage) => {
    const metric = hiringStageData?.applicantMetrics?.items.find(
      (item) => item.name === stage
    )
    return metric || { name: stage, value: 0 }
  })

  const totalApplicants = applicantStageMetrics.reduce(
    (acc, curr) => acc + curr.value,
    0
  )

  const totalProspects = hiringStageMetrics.reduce(
    (acc, curr) => acc + curr.value,
    0
  )

  /*** TOTALS ***/
  const total = selectedType === 'prospects' ? totalProspects : totalApplicants
  const items =
    selectedType === 'prospects' ? hiringStageMetrics : applicantStageMetrics

  return (
    <div className="flex h-full flex-1 flex-col gap-2 overflow-hidden rounded border border-doubleNickel-gray-200 p-3 shadow-sm">
      <div className="flex flex-col">
        <div className="flex flex-row items-baseline gap-4 text-base font-medium text-doubleNickel-gray-900">
          <Box
            onClick={() => setSelectedType('prospects')}
            className={cn(
              ' hover:cursor-pointer hover:underline',
              selectedType === 'prospects'
                ? 'text-doubleNickel-gray-800'
                : 'font-normal text-doubleNickel-gray-400'
            )}
          >
            Prospects
          </Box>

          {/* <Box
            onClick={() => setSelectedType('applicants')}
            className={cn(
              'hover:cursor-pointer hover:underline',
              selectedType === 'applicants'
                ? 'text-doubleNickel-gray-800'
                : 'font-normal text-doubleNickel-gray-400'
            )}
          >
            Applicants
          </Box> */}
        </div>
        <div className="text-sm text-doubleNickel-gray-600">
          Number of candidates throughout the application process
        </div>
      </div>
      <div className="flex flex-1 flex-row items-center justify-around py-2">
        {items.map((metric) => (
          <Box
            className="flex flex-col items-start justify-center p-3 hover:cursor-pointer hover:bg-doubleNickel-gray-100"
            key={metric.name}
            onClick={() => {
              if (selectedType === 'applicants') {
                onMetricClick('applicantStages', metric.name)
              } else {
                onMetricClick('applicantStages', `PROSPECT-${metric.name}`)
              }
            }}
          >
            <div className="flex flex-row items-baseline gap-2 text-2xl text-doubleNickel-gray-800">
              {metric.value}
              <div className="text-sm font-medium text-doubleNickel-success-500">
                {`${total ? Math.round((metric.value / total) * 100) : 0}%`}
              </div>
            </div>
            <div className="flex flex-row items-center gap-2 text-sm font-medium text-doubleNickel-gray-500">
              {formatSnakeValue(metric.name)}
            </div>
          </Box>
        ))}
      </div>
    </div>
  )
}

export default ProspectStatistics
